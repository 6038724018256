<template>
  <img src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0190d991-013a-8c74-611a-8aa2e068f0f8" alt="" style="display: block; width: 100%;">
</template>

<script>
export default {
  name: "gift-clothes",
  created(){
    document.title = "限量周边抢先穿";
  }
}
</script>

<style lang="scss">

</style>
